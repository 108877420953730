.main-title{
    text-align: center;
    margin: 50px 0;
    h1{
        margin: 50px 0 20px;
        font-size: 53px;
        color: #d47186;
        letter-spacing: 15px;
        text-transform: uppercase;
    }
    p{
        letter-spacing: 6px;
        color: #524e4e;
    }
}

.title2{
    text-align: center;
    color: gray;
    margin: 15px 0;
}

.text1{
    margin: 8px 0;
    line-height: 1.8em;
    text-indent: 2em;
}