.header{
    background-color: #d47186;
    padding: 20px;
    &__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;    
    }
}
.titlelink{
    a{
        text-decoration: none;
        color: black;
        font-size: 25px;
    }
}
nav{
    a{
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-left: 35px;
        text-decoration: none;
        color: black;
        &:hover{
            color: #11e6b0;
            text-decoration: underline;
        }
    }
}
