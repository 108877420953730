.cats{
    display: flex;
    border-top: 2px solid #eeeeee;
    justify-content: center;
    &__list{
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__item{
        text-align: center;
        background-color: #e4d3d9;
        margin: 14px 7px;
        padding: 15px 15px 10px;
        cursor: pointer;
        transition: all .2s ease;
        &:hover{
            background-color: #11e6b0;
            transform: scale(1.1);
            z-index: 10;
        }
        p{
            font-size: 20px;
            letter-spacing: 2px;
        }
        img{
            height: 10vw;
        }
    }
}
