.random{
    display: flex;
    margin-top: 20px;
    & > div{
        width: 33%;
    }
}
.update-text{
    text-align: center;
    color: rgb(211, 211, 211);
}
.link-api{
    font-weight: bold;
    color: #d47186;
}
.link-api:hover{
    color: #59bd16;
}